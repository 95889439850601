import React, { useEffect, useState } from 'react'
import Modern from './Modern'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { EVENT_INFO, GUEST_INFO } from '../../GraphQL/Queries'
import { OPEN_INVITE } from '../../GraphQL/Mutations'
import Elegant from './Elegant'
import SweetXv from './SweetXv'
import Simple from './Simple'

export default function Router({preview}) {

  function obtenerNumerosAntesDePrimeraJ(cadena) {
    const numeros = [];
    let posicion = 0;
  
    while (posicion < cadena.length && cadena[posicion] !== 'J') {
      let numero = '';
  
      // Avanzamos hasta encontrar un dígito o el final de la cadena
      while (posicion < cadena.length && !isNaN(parseInt(cadena[posicion]))) {
        numero += cadena[posicion++];
      }
  
      if (numero !== '') {
        numeros.push(numero);
      }
  
      if (cadena[posicion] === 'J') {
        break; // Detener la búsqueda al encontrar la primera "J"
      }
  
      posicion++;
    }
  
    return numeros.join('');
  }

  const [style, setStyle] = useState("elegant")
  const {url , fam} = useParams()
  const [guest , setGuest] = useState({
    id:null,
    nameInvitation: "",
    maxGuests:0,
    ok:false
  })
  const [event , setEvent] = useState({
    nameInvitation: "",
    maxGuests:0,
    ok:false
  })
  const [showInvite , setShowInvite] = useState(false)
  // el id de la boda
  // el nombre del familiar se busca ? 
  const {data , loading} = useQuery(EVENT_INFO, {variables:{
    nameEvent: preview !== undefined ? preview : url
  }})

  const [getDataGuest ] = useLazyQuery(GUEST_INFO)
  const [openInvite] = useMutation(OPEN_INVITE)

  
  useEffect(()=>  {

    if(fam !== undefined){
      let idFam = Number(obtenerNumerosAntesDePrimeraJ(fam))
      openInvite({variables:{
        id: idFam
      }})
    }
    
    if(!loading){ 
      console.log("DATA LOADED", data.events.data[0].attributes)
      setStyle(data.events.data[0].attributes.style !== null ? data.events.data[0].attributes.style : "modern")
      setEvent({
        id:data.events.data[0].id,
        nameGroom:data.events.data[0].attributes.nameGroom,
        nameBride:data.events.data[0].attributes.nameBride,
        microEvents: data.events.data[0].attributes.microEvents.map(e=>{
          return {
            title:e.title,
            place: e.place,
            address: e.address,
            dateTime: e.dateTime,
            googleLink: e.googleLink
          }
        }),
        showRings:data.events.data[0].attributes.showRings,
        date:data.events.data[0].attributes.date,
        giftRegistry:data.events.data[0].attributes.giftsRegistry,
        fromGift:data.events.data[0].attributes.fromGift,
        style:data.events.data[0].attributes.style,
        sobre: data.events.data[0].attributes.sobre,
        bankAccount: data.events.data[0].attributes.bankAccount,
        countDownPhoto: data.events.data[0].attributes.countdown.data?.attributes.url,
        thanksPhoto: data.events.data[0].attributes.Thanks.data?.attributes.url,
        religiousPhoto:data.events.data[0].attributes.religiousPhoto.data?.attributes.url,
        bacheloretesPhoto:data.events.data[0].attributes.bacheloretesPhoto.data?.attributes.url,
        venuePhoto:data.events.data[0].attributes.venuePhoto.data?.attributes.url,
        mainPhoto: data.events.data[0].attributes.mainPhoto.data[0].attributes.url,
        extra: data.events.data[0].attributes.extraData,
        galery:data.events.data[0].attributes.Galery.data.map(e=>{ 
          return {url: e.attributes.url
}        })

      })
      setShowInvite(true)
    }

  },[data])
  // si no existe el usuario ignorar la confirmación personalizada 
// y tiene que poner nombre e invitados

useEffect(()=>{
  const getData = async ()  =>{
    if(fam !== undefined){
      let idFam = Number(obtenerNumerosAntesDePrimeraJ(fam))
      const dataGuest = await getDataGuest({variables:{
        id:idFam
      }})
      if(dataGuest.data.guest.data !== null){
        setGuest({
          id:dataGuest.data.guest.data.id,
          nameInvitation: dataGuest.data.guest.data.attributes.nameInvitation,
          maxGuests:dataGuest.data.guest.data.attributes.maxGuests,
          ok:true
        })
      }
    }
  }

  getData()

},[fam])
  




  return (
    <div>
    {
      showInvite &&

      {
        'modern':  <Modern preview={preview} event={event} guest={guest} style={style} />,
        'modernxv': <SweetXv preview={preview} event={event} guest={guest} style={style}/>,
        'elegant': <Elegant preview={preview} event={event} guest={guest} style={style}/>,
        'simple': <Simple preview={preview} event={event} guest={guest} style={style}/>,
      }[style]
     
    }
    </div>
  )
}
