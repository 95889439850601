import React from 'react'

export default function PhotoPort({image , showRings , extra , bgColor}) {

  console.log(extra.customMessageArch)
  return (
<div className={` mt-4 bg-[${bgColor}]`}>
  {
    showRings &&


<div className="w-full flex justify-center items-center">
  <img
    className="w-24"
    src="https://images.squarespace-cdn.com/content/v1/6307bfe4b2ee6509f84835bf/5d62b48f-3b98-49b9-a799-0570214dbd7c/ITINERARIA+ANILLOS.png?format=1000w"
    alt="wedding"
  />
</div>

  }
    
    <div className="flex justify-center sm:ml-auto sm:mr-auto sm:max-w-96">
    
    <img
      className=" h-3/4 w-3/4  rounded-t-full"
      src={image.url}
      alt="wedding"
      />
      
      </div>

      <div className='flex justify-center mx-4'>
      <p className="font-serif  mx-auto  my-2 text-center text-md sm:text-lg text-black ">
        {
          extra.customMessageArch ?  
          
          extra.customMessageArch
          : 
          "COMPARTE ESTE MOMENTO ESPECIAL"
        }
      
      </p>
      </div>
      </div>

      

  )
}
