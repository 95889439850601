import React from 'react'
import HeroMega from '../SimpleComponents/HeroMega'
import MicroEventsElegance from '../InvitesComponents/ElegantComponents/MicroEventsElegance'


export default function Simple({guest , event , preview , style}) {
  return (
    <div>

    <HeroMega guest={guest} style={style} preview={preview} photo={event.mainPhoto} extraInf={event.extra} groom={event.nameGroom} bride={event.nameBride} date={event.date}/>

    <MicroEventsElegance 
        bgColor={event.extra.backgroundColor ?? null}
        extra={event.extra}
        events={event.microEvents} 
        placesPhotos={[
          {name :"Boda Religiosa", image :event.religiousPhoto} , 
          {name :"Recepción", image : event.venuePhoto} ,
          {name :"Despedida de soltera", image : event.bacheloretesPhoto},
          {name :"Boda Civil", image : event.bacheloretesPhoto}
          
        ]
      } />
      </div>
  )
}
