import { EnvelopeIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function DressCode({ bgColor , dressCode, imageDressCode, textDressCode , titleDressCode}) {
  return (
    <div className={` mt-4 bg-[${bgColor ?? "white"}}]  font-serif my-2`} >
    <div className="mx-auto max-w-7xl py-2 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
    <div className="text-center">
          <h2 className="text-2xl uppercase font-bold tracking-tight text-gray-900 sm:text-2xl ">{titleDressCode ? titleDressCode : "DRESS CODE"}</h2>
          <p className="mx-auto max-w-xl text-lg text-gray-500 sm:mt-4">
            {dressCode ? dressCode : "FORMAL"}
          </p>
        </div>
      
      <div className={`mt-8 justify-center}`} >
        
        <div className={`flex flex-col  justify-evenly`}>
    
          <div className='-mt-6 mx-auto'>
              <img width={220} src={imageDressCode ? imageDressCode : 'https://pilcrow.mx/formal.png'}></img>
          </div>  
              <p className="mx-auto px-12 text-lg text-gray-500 sm:mt-4">
                {
                textDressCode ? 
                textDressCode : 
                
             " Queremos que esta celebración también la disfrutes tanto como nosotros, NO NIÑOS."
                }
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

