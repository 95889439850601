import React, { useEffect, useState } from 'react'

export default function HeroMega({groom, bride , photo , preview , date , guest , extraInf}) {


  const [showInvitados , setShowinvitados] = useState(true)

  useEffect(()=>{

    setTimeout(()=>{
      setShowinvitados(false)
    },2000)
  },[])


  return (
    <div className='h-screen font-cormorant uppercase' >
        
                <div className="absolute inset-0 bg-black">
                  <img
                    className={`${preview ? "  h-1/6 ": "h-full"} w-full object-cover opacity-50`}
                    src={photo}
                    alt="wedding"
                  />
                </div>



                {
                  showInvitados ?

<div className="absolute inset-0 text-white flex flex-col justify-center items-center text-6xl   text-gray-900">
      <h2 className="  text-xl uppercase text-white sm:text-2xl">{guest.nameInvitation}</h2>
      </div>
                  : 

<div>
                {
                  extraInf.logo &&
                  
                  <div className="absolute text-black top-0 w-full bg-white py-6 opacity-80 text-center">
            
                   <span className="relative">
                    {
                      extraInf.logo && 
                      <img
                      className="mx-auto w-28"
                      src={extraInf.logo}
                      alt="wedding"
                      />
                    }
                       
                   </span>

            
          </div>
                }

      <div className="absolute inset-16  flex justify-center text-white">
              <div className="text-center text-lg sm:text-3xl flex flex-col sm:flex-row">
                    <p className="-my-1  font-serif mx-1">{groom}</p>
                    <p className="-my-1  font-serif mx-1"> & </p>
                    <p className=" -my-1 font-serif mx-1">{bride}</p>

              </div>
      </div>

      {/* Fecha grande en el centro */}
      <div className="absolute inset-0 text-white flex flex-col justify-center items-center text-6xl   text-gray-900">
      <hr className="w-px h-12 sm:h-24 bg-white border-none" />


        <p>{new Date(date + "T00:00:00").getDate()}</p>
        <p>{new Date(date + "T00:00:00").toLocaleString("es-ES", { month: "short" })}</p>
        <p>{new Date(date + "T00:00:00").getFullYear().toString().slice(2,4)}</p>
      <hr className="w-px h-12 sm:h-24 bg-white border-none" />


      </div>
                </div>
                }

                

                

      


                 
        </div>
  )
}
